import { useTranslation } from '@pancakeswap/localization'
import { Button, Flex, Text, useMatchBreakpoints, Image } from '@pancakeswap/uikit'
import { styled } from 'styled-components'
import useTheme from 'hooks/useTheme'
import { useAccount } from 'wagmi'
import { NextLinkFromReactRouter } from '@pancakeswap/widgets-internal'

const Hero = () => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { theme } = useTheme()
  const { isMobile, isXs, isSm, isMd } = useMatchBreakpoints()

  return (
    <>
      <Flex width="100%" py="6px">
        <Flex flexDirection="column" maxWidth="1200px" mx="auto" px={4}>
          <Flex flexDirection={['column-reverse', 'column-reverse', 'row']} alignItems="center" mb={[6, 6, 12]}>
            <Flex flexDirection="column" justifyContent="center" width={['100%', '100%', '50%']} mb={4}>
              <Text
                as="h1"
                fontSize={['24px', '36px', '48px']}
                fontWeight="bold"
                color={theme.isDark ? '#D8B4FE' : '#6b21a8'}
                lineHeight="tight"
              >
                Trade, Earn, and Win in the Realm of GungnirDEX
              </Text>
              <Text fontSize={['16px', '18px', '20px']} color={theme.isDark ? '#E9D5FF' : '#9333ea'} maxWidth="600px">
                Dive into the world of decentralized finance with GungnirDEX. Swap tokens, earn rewards, and participate
                in exciting competitions.
              </Text>
              <Flex mt={4} flexDirection={['column', 'row']}>
                <NextLinkFromReactRouter to="/swap">
                  <Button className="bg-purple-600 text-white hover:bg-purple-700" mb={[2, 0]} mr={[0, 2]}>
                    Trade Now
                    {/* <ArrowRight className="ml-2 h-4 w-4" /> */}
                  </Button>
                </NextLinkFromReactRouter>
                {/* <Button variant="primary">Learn More</Button> */}
              </Flex>
            </Flex>
            <Image
              src="/logo.svg"
              alt="Hero"
              width={550}
              height={550}
              style={{
                // maxWidth: '100%',
                height: 'auto',
                aspectRatio: '1',
                objectFit: 'cover',
                borderRadius: '0.75rem',
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default Hero
